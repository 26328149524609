html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

svg {
  max-width: 100%;
  display: block;
}

* {
  box-sizing: border-box;
}

.main {
  padding: 40px;
  font-family: Lato, serif;
  overflow-x: hidden;
}

.languages {
  position: absolute;
  top: 10px;
  right: 42px;
}

.languages ul {
  color: #493e3a;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  display: inline-flex;
}

.languages ul li {
  cursor: pointer;
  opacity: .26;
  margin-left: 20px;
  transition: opacity .3s;
}

.languages ul li.active {
  opacity: 1;
}

.wrapper {
  flex-direction: column;
  width: calc(100vw - 80px);
  height: calc(100vh - 80px);
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width >= 992px) {
  .wrapper {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

.wrapper .video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper .video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (width <= 992px) {
  .wrapper .video video {
    background-image: url("mono-mobile-bg.b88d557c.jpg");
    background-position: center;
    background-size: cover;
  }
}

.wrapper .video:after {
  content: "";
  background-color: #0000005c;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wrapper .header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.wrapper .header:before, .wrapper .header:after {
  content: "";
  background-color: #fff;
  width: 30%;
  height: 1px;
}

@media (width >= 992px) {
  .wrapper .header {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 15vw;
    height: 100%;
    display: inline-flex;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .wrapper .header:before, .wrapper .header:after {
    content: "";
    background-color: #fff;
    width: 1px;
    height: 40%;
  }
}

.wrapper .header h1 {
  color: #fff;
  text-align: center;
  font-family: Abril Fatface;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
}

.wrapper .section {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 0 25px;
  display: flex;
}

.wrapper .section.active {
  z-index: 3;
}

.wrapper .section.active .logo {
  transform: translateY(-10px);
}

.wrapper .section.active .copy {
  opacity: 1;
  visibility: visible;
  height: auto;
  transition: visibility .6s, opacity .6s;
}

.wrapper .section.active .cta-button {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: none;
}

.wrapper .section.active .background {
  opacity: 1;
  visibility: visible;
  transform: scale(1.05);
}

.wrapper .section.one .background img {
  object-position: right;
}

.wrapper .section.one .background:before {
  top: 0;
  left: 0;
}

.wrapper .section.two .background:before {
  bottom: 0;
  right: 0;
}

@media (width <= 992px) {
  .wrapper .section {
    flex: 1;
    width: 100%;
  }
}

@media (width >= 992px) {
  .wrapper .section {
    justify-content: flex-start;
    min-height: 250px;
    margin-top: 165px;
  }

  .wrapper .section:hover {
    z-index: 3;
  }

  .wrapper .section:hover .logo {
    transform: translateY(-10px);
  }

  .wrapper .section:hover .copy {
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: visibility .6s, opacity .6s;
  }

  .wrapper .section:hover .cta-button {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: none;
  }

  .wrapper .section:hover .background {
    opacity: 1;
    visibility: visible;
    transform: scale(1.05);
  }

  .wrapper .section.one .background img {
    object-position: unset;
  }

  .wrapper .section.one .background:before {
    left: 0;
  }

  .wrapper .section.two .background:before {
    right: 0;
  }
}

.wrapper .section .logo {
  margin-bottom: 30px;
  transition: transform .6s;
}

.wrapper .section .logo svg {
  max-width: 100%;
  display: block;
}

.wrapper .section .copy {
  opacity: 0;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  height: 0;
  transition: none;
  display: flex;
}

.wrapper .section .copy h2 {
  color: #fff;
  margin-bottom: 10px;
  font-family: Abril Fatface;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2em;
}

.wrapper .section .copy p {
  color: #fff;
  text-align: center;
  border-top: 2px solid #fff;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6em;
}

.wrapper .section .copy a {
  color: #fff;
  text-decoration: none;
}

.wrapper .section .copy .cta-link {
  z-index: 1;
  flex-direction: column;
  align-items: center;
  display: inline-flex;
}

.wrapper .section .cta-button {
  opacity: 1;
  visibility: visible;
  background: none;
  border: none;
  max-width: 100%;
  height: auto;
  margin-top: -30px;
  padding: 30px;
  transition: visibility .6s, opacity .6s;
  display: block;
}

.wrapper .section .background {
  pointer-events: none;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  transition: visibility .3s, opacity .3s, transform 5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
}

.wrapper .section .background:before {
  content: "";
  z-index: 1;
  background-color: #0009;
  width: 100%;
  height: 50%;
  position: absolute;
}

@media (width >= 992px) {
  .wrapper .section .background:before {
    width: 50%;
    height: 100%;
  }
}

.wrapper .section .background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.button {
  display: inline-block;
  position: relative;
}

.button .link {
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  min-width: 250px;
  padding: 20px;
  font-size: 20px;
  display: block;
}

.button span {
  background: #fff;
  height: 3px;
  transition: width .6s;
  position: absolute;
}

.button span.top-left {
  width: 30%;
  top: 0;
  left: 0;
}

.button span.top-right {
  width: 50%;
  top: 0;
  right: 0;
}

.button span.bottom-left {
  width: 50%;
  bottom: 0;
  left: 0;
}

.button span.bottom-right {
  width: 30%;
  bottom: 0;
  right: 0;
}

.button:hover span.top-left {
  width: 50%;
}

.button:hover span.top-right, .button:hover span.bottom-left {
  width: 30%;
}

.button:hover span.bottom-right {
  width: 50%;
}

@media (width <= 768px) {
  .button span {
    height: 2px;
  }
}
/*# sourceMappingURL=index.ee14e15b.css.map */
