@import '_reset.scss';

.main {
  font-family: "Lato", serif;
  padding: 40px;
  overflow-x: hidden;
}

.languages {
  position: absolute;
  top: 10px;
  right: 42px;

  ul {
    display: inline-flex;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    color: #493E3A;

    li {
      margin-left: 20px;
      cursor: pointer;
      opacity: 0.26;
      transition: opacity 0.3s;

      &.active {
        opacity: 1;
      }
    }
  }
}

.wrapper {
  position: relative;
  width: calc(100vw - 80px);
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media (max-width: 992px) {
        background-image: url('../assets/img/mono-mobile-bg.jpg');
        background-position: center;
        background-size: cover;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.36);
    }
  }

  .header {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before,
    &::after {
      content: '';
      width: 30%;
      height: 1px;
      background-color: #fff;
    }

    @media (min-width: 992px) {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-width: 15vw;

      &::before,
      &::after {
        content: '';
        width: 1px;
        height: 40%;
        background-color: #fff;
      }
    }

    h1 {
      font-family: 'Abril Fatface';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2em;
      color: #fff;
      text-align: center;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 0 25px;
    z-index: 1;

    &.active {
      z-index: 3;

      .logo {
        transform: translateY(-10px);
      }

      .copy {
        height: auto;
        opacity: 1;
        visibility: visible;
        transition: visibility 0.6s, opacity 0.6s;
      }

      .cta-button {
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: none;
      }

      .background {
        opacity: 1;
        visibility: visible;
        transform: scale(1.05);
      }
    }

    &.one {
      .background {
        img {
          object-position: right;
        }

        &::before {
          top: 0;
          left: 0;
        }
      }
    }

    &.two {
      .background {
        &::before {
          bottom: 0;
          right: 0;
        }
      }
    }

    @media (max-width: 992px) {
      flex: 1;
      width: 100%;
    }

    @media (min-width: 992px) {
      justify-content: flex-start;
      margin-top: 165px;
      min-height: 250px;

      &:hover {
        z-index: 3;

        .logo {
          transform: translateY(-10px);
        }

        .copy {
          height: auto;
          opacity: 1;
          visibility: visible;
          transition: visibility 0.6s, opacity 0.6s;
        }

        .cta-button {
          height: 0;
          opacity: 0;
          visibility: hidden;
          transition: none;
        }

        .background {
          opacity: 1;
          visibility: visible;
          transform: scale(1.05);
        }
      }

      &.one {
        .background {
          img {
            object-position: unset;
          }

          &::before {
            left: 0;
          }
        }
      }

      &.two {
        .background {
          &::before {
            right: 0;
          }
        }
      }
    }

    .logo {
      margin-bottom: 30px;
      transition: transform 0.6s;

      svg {
        display: block;
        max-width: 100%;
      }
    }

    .copy {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: none;

      h2 {
        font-family: 'Abril Fatface';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.2em;
        color: #fff;
        margin-bottom: 10px;
      }

      p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6em;
        color: #fff;
        border-top: 2px solid #fff;
        text-align: center;
      }

      a {
        color: #fff;
        text-decoration: none;
      }

      .cta-link {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
      }
    }

    .cta-button {
      display: block;
      max-width: 100%;
      background: none;
      border: none;
      padding: 30px;
      margin-top: -30px;
      height: auto;
      opacity: 1;
      visibility: visible;
      transition: visibility 0.6s, opacity 0.6s;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transform: scale(1);
      transition: visibility 0.3s, opacity 0.3s, transform 5s;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
      }

      @media (min-width: 992px) {
        &::before {
          width: 50%;
          height: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.button {
  position: relative;
  display: inline-block;
}

.button .link {
  color: #fff;
  display: block;
  font-size: 20px;
  cursor: pointer;
  padding: 20px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  min-width: 250px;
  box-sizing: border-box;
  text-align: center;
}

.button span {
  position: absolute;
  background: #fff;
  height: 3px;
  transition: width 0.6s;
}

.button span.top-left {
  left: 0;
  top: 0;
  width: 30%;
}

.button span.top-right {
  right: 0;
  top: 0;
  width: 50%;
}

.button span.bottom-left {
  left: 0;
  bottom: 0;
  width: 50%;
}

.button span.bottom-right {
  right: 0;
  bottom: 0;
  width: 30%;
}

.button:hover span.top-left {
  width: 50%;
}

.button:hover span.top-right {
  width: 30%;
}

.button:hover span.bottom-left {
  width: 30%;
}

.button:hover span.bottom-right {
  width: 50%;
}

@media (max-width: 768px) {

  .button span {
    height: 2px;
  }
}